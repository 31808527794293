import { Helmet } from "react-helmet";

import "./upload-page.css";

export default function UploadPage(props) {
  return (
    <main className="main upload-page">
      <Helmet>
        <title>How to Upload | Wolfram Demonstrations Project</title>
      </Helmet>
      <section>
        <div className="inner">
          <h1 id="demoTitle" className="visuallyhidden">
            How to Upload
          </h1>
          <p>
            Starting in Wolfram Language XX.XX, demonstrations can be submitted
            directly from an updated authoring notebook featuring new tools to
            help improve and validate your demonstration.{" "}
            <a href={props.createNotebookDownload}>
              Get the authoring notebook here, or above in the page header.
            </a>
          </p>
          <p>
            If you do not have version XX.XX or higher you can send your
            submission as an attachment via email to XXXX@wolfram.com.
          </p>
        </div>
      </section>
    </main>
  );
}
